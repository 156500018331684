import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { SharedService } from "../shared/services/shared.service";

@Injectable({
    providedIn: 'root'
  })
  export class LogoutService {
    constructor(
      private _httpClient: HttpClient,
      private _sharedService: SharedService
    ) { }
  
    logout() {
      const data = {
        accessToken:  this._sharedService.getLocalStorage('access_token')
      }
      return this._httpClient.post(environment.apiUrl + 'auth/logout', data);
    }
  
  }