import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ChatService } from '../services/chat/chat.service';
import { LogoutService } from './logout.service';
import { SharedService } from '../shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private accessTokenKey = 'access_token';
  private refreshTokenKey = 'refresh_token';
  private idTokenKey = 'id_token';
  private tokenTimestampKey = 'token_timestamp';
  private refreshTokenExpiresIn = (30 * 24 * 60 * 60 * 1000) - (30 * 60 * 1000); // 30 days in milliseconds
  private emailKey = 'email';
  private isSignedInSubject = new BehaviorSubject<boolean>(this.isSignedIn());

  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _logoutService: LogoutService,
    private _chatService: ChatService,
    private _sharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  get getToken(): string | null {
    return this._sharedService.getLocalStorage(this.accessTokenKey);    
  }

  saveTokens(tokens: { AccessToken: string, IdToken: string, RefreshToken?: string, }): void {
    this._sharedService.setLocalStorage(this.accessTokenKey, tokens.AccessToken);
    if(tokens.RefreshToken){
      const issuedAt = new Date().getTime();
      this._sharedService.setLocalStorage(this.refreshTokenKey, tokens.RefreshToken);
      this._sharedService.setLocalStorage(this.tokenTimestampKey, issuedAt.toString());
    }
    this._sharedService.setLocalStorage(this.idTokenKey, tokens.IdToken);
    this.isSignedInSubject.next(true); // Notify subscribers that the user is signed in
  }

  getRefreshToken(): string | null {
    return this._sharedService.getLocalStorage(this.refreshTokenKey);
  }

  removeTokens(): void { 
    this._sharedService.clearLocalStorage();
    this.isSignedInSubject.next(false); // Notify subscribers that the user is signed out
  }

  refreshToken(): Observable<any> {
    const refreshToken = this._sharedService.getLocalStorage(this.refreshTokenKey)
    const issuedAt = parseInt(this._sharedService.getLocalStorage(this.tokenTimestampKey) || '0', 10);
    const currentTime = new Date().getTime();
    if (currentTime - issuedAt > this.refreshTokenExpiresIn) {
      // this._toaster.warning('Your session has timed out. Please log in again to continue.');
      this.removeTokens();
      this._router.navigate(['/']);
      return EMPTY; // Return an empty observable if the token is expired
    } else {
      const email = this._sharedService.getLocalStorage('email');
      return this._httpClient.post<any>(`${environment.apiUrl}/auth/refresh-tokens`, { refreshToken, email }).pipe(
        tap(response => {
          this.saveTokens({
            AccessToken: response.AccessToken,
            IdToken: response.IdToken
          });
        }),
        catchError(error => {
          // this._toaster.error('Your session has timed out. Please log in again to continue.', 'Oops!!');
          this.removeTokens();
          this._router.navigate(['/']);
          return throwError(() => error);
        })
      );
    }
  }

  isTokenExpired(token: string): boolean {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jwtPayload = JSON.parse(atob(base64));
        const expiry = (jwtPayload.exp * 1000) || 0;
        return Date.now() > expiry;
      } catch (error) {
        console.error('Failed to decode token:', error);
        return true; // Consider the token as expired if it can't be decoded
      }
    }
    return true;
    
  }

  isSignedIn(): boolean {
    const token = this.getToken;
    return token != null && !this.isTokenExpired(token);
  }

  get isSignedIn$(): Observable<boolean> {
    return this.isSignedInSubject.asObservable();
  }
  
  logout() {
    this._logoutService.logout().subscribe(res => {
      this.removeTokens();
      this._router.navigate(['/']);
      this._chatService.disconnectWebSocket();
      location.reload();
    })
  }
}
